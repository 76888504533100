@font-face { font-family: LeagueGothic; src: url(LeagueGothic.otf); }

header {
    background-color: #36454f;
    height: 300px;
    color: #d8d8d8;
    font-family: LeagueGothic;
}

header h1, header h2, header h3 { margin: 0; text-transform: uppercase; font-weight: normal; }

header h1 { padding: 50px 0 0 70px; font-size: 120px; }
header h2 { padding: 0 0 0 70px; font-size: 45px; }

header .firstname { color: white }
header .lastname { color: #62a5f4 }

header ul { position: absolute; right: 50px; top: 15px; }
header ul { list-style-type: none; text-transform: uppercase; font-size: 30px; line-height: 50px; }
header ul .nospam:before { content: '@'; }
header ul .web a { text-decoration: none; color: inherit; }

header h3 { padding: 30px 0 0 0; width: 25%; text-align: right; color: #36454f; font-size: 60px; }
header h3 > span { padding-right: 10px; }

@media only screen and (max-width: 1169px)
{
	header h1 { padding-top: 20px; }
	
	header ul { left: 30px; top: 200px; }
	header ul li { float: left; padding-right: 30px; }
	header ul li.location { display: none }
	header ul li.web { display: none }
}

@media only screen and (max-width: 600px)
{
	header { height: 200px; }
	header h1 { font-size: 60px; }
	header h2 { font-size: 30px; }
	header ul { top: 125px; font-size: 20px; }
}